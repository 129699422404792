// 需求状态，发布方
export const REQUIREMENT_STATE = {
  CANCELED:           { label: '已取消', value: '-1', step: 3, cssClass: 'info' },
  WAITING_PAY:        { label: '待支付', value: '1010', step: 2, cssClass: 'warn' },
  GRABBING_ORDER:     { label: '抢单中', value: '1021', step: 3, cssClass: 'success' },
  PAYMENT_IS_OVERDUE: { label: '超时未支付', value: '1024', step: 1, cssClass: 'warn' },
  WAITING_EMPLOY:     { label: '待雇用', value: '1031', step: 1, cssClass: 'warn' },
  EMPLOYED:           { label: '已雇用', value: '1041', step: 4, cssClass: 'success' },
  WORKING:            { label: '工作中', value: '1050', step: 5, cssClass: 'warn' },
  WAITING_CHECK:      { label: '待验收', value: '1052', step: 6, cssClass: 'warn' },
  CHECK_PASS:         { label: '已验收', value: '1061', step: 1, cssClass: 'success' },
  EVALUATED:          { label: '已评价', value: '1090', step: 7, cssClass: 'success' },
  EXPIRED:            { label: '已逾期', value: '1051', step: 1, cssClass: 'danger' },
  REFUND:             { label: '已退款', value: '1081', step: 3, cssClass: 'success' },
  REFUND_IN_PROCESS:  { label: '退款中', value: '1080', step: 1, cssClass: 'warn', show: true }, // 已支付，未开始工作，退款
  REFUNDED_ERROR:     { label: '退款失败', value: '1082', step: 1, cssClass: 'danger', show: true },
  PROVIDER_IN_APPEAL: { label: '申诉中', value: '1070', step: 1, cssClass: 'warn', show: true }, // 服务商已申诉
  APPEAL_SUCCEED:     { label: '申诉成功', value: '1073', step: 1, cssClass: 'success', show: true }, // 申诉成功、失败，是对于下单用户，相对的服务商申诉状态相反
}

/**
 * 需求交付时间类型
 */
export const DUE_TIME_TYPE = {
  HOUR: { label: '小时', value: 1 },
  DAY:  { label: '天', value: 2 },
}

// 任务，抢单方
export const TASK_STATE = {
  CANCELED:           { label: '已取消', value: '-1', cssClass: 'info' },
  WAITING_PAY:        { label: '待支付', value: '1010', cssClass: 'warn' },
  GRABBING_ORDER:     { label: '抢单中', value: '1021', cssClass: 'success' },
  WAITING_EMPLOY:     { label: '待雇用', value: '1031', cssClass: 'warn' },
  EMPLOYED:           { label: '已雇用', value: '1041', cssClass: 'success' },
  EMPLOY_REJECT:      { label: '被驳回', value: '1042', cssClass: 'warn' },
  WORKING:            { label: '工作中', value: '1050', cssClass: 'warn' },
  WAITING_CHECK:      { label: '待验收', value: '1052', cssClass: 'warn' },
  CHECK_PASS:         { label: '已验收', value: '1061', cssClass: 'success' },
  EVALUATED:          { label: '已评价', value: '1090', cssClass: 'success' },
  EXPIRED:            { label: '已逾期', value: '1051', cssClass: 'danger' },
  REFUND:             { label: '已退款', value: '1081', cssClass: 'success' },
  REFUNDED_ERROR:     { label: '退款失败', value: '1082', cssClass: 'danger', show: true },
  PROVIDER_IN_APPEAL: { label: '申诉中', value: '1070', cssClass: 'warn', show: true }, // 服务商已申诉
  APPEAL_SUCCEED:     { label: '申诉失败', value: '1073', cssClass: 'success', show: true }, // 申诉成功、失败，是对于抢单用户，相对的发布方申诉状态相反
}

export const REQUIREMENT_MODE = {
  NORMAL: { label: '正常交易', value: '0', cssClass: 'info' },
  BOUNTY: { label: '计时悬赏', value: '1', cssClass: 'danger' },
}
