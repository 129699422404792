<!-- 
   分页 报错 不用管 是子组件 变化 父组件页变化 导致的
 -->
<template>
  <div class="Pagination">
    <div class="all-page">共{{total}}条</div>
    <!-- 当 当前页面 大于1 上一页 按钮显示 -->
    <div class="previous-page" v-if="page > 1" @click="previousPage">上一页</div>
    <el-pagination background layout="prev, pager, next" :total="total" :pager-count="count" :page-size="size" :current-page.sync="page" @current-change="handleCurrentChange"></el-pagination>
    <!-- 小于 当前页面总数 -->
    <div class="next-page" v-if="page < lastPage" @click="nextPage">下一页</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    lastPage() {
      return Math.ceil(this.total / this.size);
    },
  },
  props: {
    count: {
      type: Number,
      default: 11,
    },
    total: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 8,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    // 上一页操作
    previousPage() {
      // 防抖
      if (this.show) return;
      this.show = true;
      let val = this.page - 1;
      this.$emit("handlePage", val);
      setTimeout(() => {
        this.show = false;
      }, 500);
    },
    // 下一页操作
    nextPage() {
      if (this.show) return;
      this.show = true;
      let val = this.page + 1;
      this.$emit("handlePage", val);
      setTimeout(() => {
        this.show = false;
      }, 500);
    },
    // 页面发生变化
    handleCurrentChange(val) {
      this.$emit("handlePage", val);
    },
  },
};
</script>
<style lang="less" scoped>
// 分页
.Pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  /deep/ .el-pager .number {
    cursor: pointer;
  }
  /deep/ .btn-prev {
    display: none;
  }
  /deep/ .btn-next {
    display: none;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #fc3737;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    background: #fc3737;
    color: #ffffff;
  }
}
//
.all-page {
  font-weight: 400;
  color: #7c7c7c;
  line-height: 22px;
  font-size: 12px;
  margin-right: 13px;
}
.previous-page,
.next-page {
  width: 59px;
  height: 30px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
  letter-spacing: 1px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.previous-page:hover {
  background: #fc3737;
  color: #ffffff;
}
.next-page:hover {
  background: #fc3737;
  color: #ffffff;
}
</style>