<template>
  <el-card class="box-card">
    <!-- 商品 -->
    <div class="contents">
      <div class="head">
        <div class="title">
          任务订单<span>（{{ total }}）</span>
        </div>

      </div>
      <el-empty v-if="showEmpty" :image="require('@/assets/imgs/no_hall.png')" description="您还未接单"></el-empty>
      <div class="main" v-show="!showEmpty">

        <div class="sort-container">
          <el-button size="mini" plain @click="clickSortCollectTime" disabled>
            抢单时间<i class="el-icon-arrow-down el-icon--right" :class="!sort.collectTimeDesc ? 'asc' : ''"></i>
          </el-button>
          <!--<el-button size="mini" plain @click="clickSortDueTime">-->
          <!--  交付时间<i class="el-icon-arrow-down el-icon&#45;&#45;right" :class="!sort.dueTimeDesc ? 'asc' : ''"></i>-->
          <!--</el-button>-->
        </div>

        <div class="table-header">
          <div class="column-item title">任务标题</div>
          <div class="column-item provider">雇主</div>
          <div class="column-item number">任务金额</div>
          <div class="column-item mode">任务类型</div>
          <div class="column-item state">任务状态</div>
          <div class="column-item time collect-time">抢单时间</div>
          <div class="column-item time due-time">交付时间</div>
          <div class="column-item option">操作</div>
        </div>
        <div class="table-body">
          <div class="table-row" v-for="item in renderList" :key="item.id">
            <div class="column-item title" @click="clickDetail(item)">
              <el-avatar icon="el-icon-user-solid" shape="square"
                         :size="50" :src="item.requirement.img"></el-avatar>
              <span class="text">{{ item.requirement.title }}</span>
            </div>
            <div class="column-item provider" @click.stop="clickChat(item)">
              <el-avatar icon="el-icon-user-solid"
                         :size="50" :src="item.publishUser.avatarImg"></el-avatar>
              <span class="text">{{ item.publishUser.nickName }}</span>
            </div>
            
            <div class="column-item number">￥{{ item.requirement.amount }}</div>
            <div class="column-item mode" :class="item.requirement.modeClass">{{ item.requirement.mode }}</div>
            <div class="column-item state" :class="item.stateClass">{{ item.state}}</div>
            <div class="column-item time collect-time">{{ item.collectTime }}</div>
            <div class="column-item time due-time" v-if="item.state === TASK_STATE.WORKING.label">
              <countdown-timer :end-time="+moment(item.expirationDate).add(item.dueTime)" />后交付
            </div>
            <div class="column-item time due-time" v-else>
              {{ item.dueTimeLabel }}
            </div>
            <div class="column-item option">
              <el-button size="small" v-if="item.state === TASK_STATE.EMPLOYED.label" @click="startWork(item)">开始工作</el-button>
              <el-button size="small" v-if="item.state === TASK_STATE.WORKING.label" @click="completeWork(item)">完成工作</el-button>
            </div>
          </div>
        </div>
        <Pagination :total="total" :size="queryParam.pageSize" :page="queryParam.pageNo" @handlePage="PageChange" v-if="total > queryParam.pageSize" />
      </div>

    </div>
  </el-card>
</template>

<script>
import Pagination from "@/components/Pagination/index.vue";
import moment from "moment/moment";
import { DUE_TIME_TYPE, REQUIREMENT_MODE, TASK_STATE } from "@/constants/requirement";
import CountdownTimer from "@/components/FlipDown/countdownTimer.vue";
import { mapActions } from "vuex";
export default {
  name: 'DemandCollectionList',
  data() {
    return {
      list: [],
      sort: {
        collectTimeDesc: true,
        dueTimeDesc: true
      },
      showEmpty: false,
      total: 0,
      queryParam: {
        grabUserId: this.$store.getters.getUser.id,
        pageNo: 1,
        pageSize: 10,
        sortType: ''
      },
      curItem: '',
      workDialog: {
        title: '',
        desc: ''
      },
      TASK_STATE,
      DUE_TIME_TYPE,
    };
  },
  components: {
    CountdownTimer,
    Pagination
  },
  created() {
    this.id = this.$store.getters.getUser.id;
    this.getList();
  },
  mounted() {

  },
  computed: {
    renderList() {
      let retList = this.list
      retList.forEach(item => {
        if (!item.requirement) item.requirement = {}
        if (item.requirement && item.requirement.filePath) {
          item.requirement.img = item.requirement.filePath.split(',')[0]
        } else {
          item.requirement.img = ''
        }
        item.collectTime = moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
        // 交易模式
        let mode = Object.keys(REQUIREMENT_MODE).find(key => REQUIREMENT_MODE[key].value === item.requirement.type)
        if (mode) {
          item.requirement.mode = REQUIREMENT_MODE[mode].label
          item.requirement.modeClass = REQUIREMENT_MODE[mode].cssClass
        }
        // 订单状态
        let key = Object.keys(this.TASK_STATE).find(key => +this.TASK_STATE[key].value === item.orderState)
        if (key) {
          item.state = this.TASK_STATE[key].label
          item.stateClass = this.TASK_STATE[key].cssClass
        }
        if (item.achieveTimeType && item.achieveTimeInt) {
          if (item.achieveTimeType === this.DUE_TIME_TYPE.HOUR.value) item.dueTime = 1000 * 60 * 60 * item.achieveTimeInt
          else item.dueTime = 1000 * 60 * 60 * 24 * item.achieveTimeInt
          let type = item.achieveTimeType === this.DUE_TIME_TYPE.HOUR.value ? this.DUE_TIME_TYPE.HOUR.label : this.DUE_TIME_TYPE.DAY.label
          item.dueTimeLabel = item.achieveTimeInt + type + '交付'
        }
      })
      return retList;
    }
  },
  methods: {
    moment,
    ...mapActions(['showChat']),
    // 获取用户发布需求
    getList() {
      let url = `/app-api/hiring/requirement-order/page`;
      
      let arr = []
      arr.push(this.sort.collectTimeDesc ? 'descByCreateTime' : 'ascByCreateTime')
      // arr.push(this.sort.dueTimeDesc ? 'descByExpirationDate' : 'ascByExpirationDate')
      this.queryParam.sortType = arr.join(',')

      this.$axios
          .get(url, {params: this.queryParam})
          .then((res) => {
            if (res.data.code === 0) {
              this.list = res.data.data.list;
              this.total = res.data.data.total
              this.showEmpty = !this.list || !this.list.length
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    PageChange(val) {
      this.queryParam.pageNo = val;
      this.getList();
    },
    startWork(item) {
      this.curItem = item
      this.$confirm("确定开始工作吗？")
          .then(() => {
            this.$axios
                .post(`/app-api/hiring/requirement-order/start-work`, {
                  id: this.curItem.id,
                })
                .then((res) => {
                  if (res.data.code === 0) {
                    this.$notify.success('开始工作成功')
                    this.getList()
                  }
                })
                .catch(() => {
                  // this.$notify.error('开始工作失败，请稍后再试')
                  // setTimeout(() => this.getList(), 500)
                })
          })
          .catch(() => {
            this.$message.info('已取消开始工作');
          });
      
    },
    completeWork(item) {
      this.curItem = item
      this.$axios
          .post(`/app-api/hiring/requirement-order/complete-work`, {
            id: this.curItem.id,
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success('工作完成')
              this.getList()
            }
          })
          .catch(() => {
            // this.$notify.error('工作完成失败，请稍后再试')
            // setTimeout(() => this.getList(), 500)
          })
    },
    clickDetail(item) {
      this.$router.push({
        name: 'SeekingHallDetail',
        query: {
          id: item.id
        }
      })
    },
    clickChat(item) {
      if (item.publishUser.userPhone === this.$store.getters.getUser.userPhone) {
        this.$notify.info('您不能向自己在线咨询！')
        return
      }
      this.showChat({
        show: true,
        phone: item.publishUser.userPhone,
        goods: {
          id: '',
          name: '',
          desc: '',
          img: ''
        }
      });
    },
    clickDelete(item) {
      this.$confirm("确定删除所选任务吗？")
          .then(() => {
            this.$axios
                .delete(`/app-api/hiring/requirement-order/delete`, {
                  params: {
                    id: item.order.id,
                  }
                })
                .then((res) => {
                  if (res.data.code === 0) {
                    this.$message.success('删除成功!');
                    setTimeout(() => {
                      location.reload();
                    }, 500);
                  }
                })
                .catch((err) => {
                  this.$message.error("删除失败！" + err)
                });
          })
          .catch(() => {
            this.$message.info('已取消删除');
          });
    },
    clickSortCollectTime() {
      this.sort.collectTimeDesc = !this.sort.collectTimeDesc
      this.getList()
    },
    clickSortDueTime() {
      this.sort.dueTimeDesc = !this.sort.dueTimeDesc
      this.getList()
    },
  },
};

</script>

<style scoped lang="less">
// 商品
.contents {
  padding: 10px;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    
    .title {
      font-size: 24px;
      font-weight: bold;
    }
    
    .add_serve {
      cursor: pointer;
      font-size: 18px;
    }
  }

  .main {
    .sort-container {
      margin: 20px 0;
      i {
        transform: rotate(0deg);
        transition: transform .5s;
        &.asc {
          transform: rotate(180deg);
          transition: transform .5s;
        }
      }
    }

    .column-item {
      width: 80px;
      color: #999999;
      padding: 5px 10px;
      text-align: center;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis; //文本溢出显示省略号
      white-space: nowrap; //文本不会换行
      .text {
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        white-space: nowrap; //文本不会换行
        margin-left: 5px;
      }
      &.title {
        flex: 1;
        font-size: 16px;
        text-align: left;
      }
      &.provider {
        width: 140px;
      }
      &.state, &.mode {
        &.info {
          color: #999999;
        }
        &.success {
          color: #209F85;
        }
        &.warn {
          color: #ee914e;
        }
        &.danger {
          color: #FF3D3D;
        }
      }

      &.time {
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
        font-size: 14px;
        width: 90px;
      }
      &.option {
        width: 100px;
      }
      &.due-time {
        width: 120px;
      }
    }

    .table-header {
      display: flex;
      background-color: #F4F4F4;
      border: 1px solid #E9E9E9;
      font-size: 16px;
    }
    .table-body {
      .table-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 0;
        border-bottom: 1px solid #E9E9E9;
        
        .title {
          color: #3D8BFF;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .provider {
          
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        .title {
          color: #3D8BFF;
          cursor: pointer;
        }
        .publish-time {
          flex-basis: 100%;
          padding: 0 20px;
          color: #999999;
          background-color: #F4F4F4;
        }
        .number {
          color: #fc3737;
        }
        .mode {
          //color: #090909;
        }
        .due-time {
          font-size: 12px;
          color: #ee914e;
        }
        .option {
          text-align: right;
        }
      }
    }
  }
}
</style>
