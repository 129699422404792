<template>
  <div>
    <div v-if="show">
      <span v-if="time.days && time.days !== '00'"><span class="number">{{ time.days }}</span><span class="unit">天</span></span>
      <span class="number">{{ time.hours || '00' }}</span><span class="unit">小时</span>
      <span class="number">{{ time.minutes }}</span><span class="unit">分</span>
      <span class="number">{{ time.seconds }}</span><span class="unit">秒</span>
    </div>
    <div v-if="!show">{{ expiredContent }}</div>
  </div>
</template>
<script>
import moment from "moment";

moment.locale("zh-cn"); //汉化
export default {
  name: 'countdown-timer',
  props: {
    endTime: {
      type: Number,
    },
    expiredContent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      //倒计时内容
      time: {
        days: "",
        hours: "",
        minutes: "",
        seconds: ""
      },
      timer: '',
      now: +moment()
    }
  },
  methods: {
    reset() {
      if (this.timer) clearInterval(this.timer);
      this.time = {
        days: "",
        hours: "",
        minutes: "",
        seconds: ""
      }
    },
    // 计算 当前时间 到 截止时间 差值的：天、时、分、秒
    countdown() {
      let _this = this;
      _this.now = +moment()
      const diffTime = moment(_this.endTime).diff(_this.now);
      Object.keys(_this.time).forEach(key => {
        const timeVal = moment.duration(diffTime)[key]();
        if (timeVal) _this.time[key] = _this.prefixInteger(timeVal, 2);
      });
      return _this.time
    },
    // 初始化倒计时
    initCountdown() {
      let _this = this;
      if (_this.endTime <= _this.now) return

      _this.time = _this.countdown(); // 首次

      // 定时器
      _this.timer = setInterval(() => {
        if (_this.endTime <= _this.now) {
          _this.reset()
        } else {
          _this.time = _this.countdown();
        }
      }, 1000);
    },
    // 补零
    prefixInteger(num, length) {
      return (Array(length).join("0") + num).slice(-length);
    },
  },
  computed: {
    show() {
      return this.endTime >= this.now
    }
  },
  created() {
    this.reset()
    this.initCountdown();
  },
  beforeDestroy() {
    this.reset()
  },
}
</script>
<style lang="less" scoped>
  .number {
    font-weight: 600;
  }
</style>
